const IMAGE = Object.freeze({
    OFFICIAL_LOGO: {
        name: 'official_logo',
        svg: true,
        common: true,
    },
    CHECK_CIRCLE_OUTLINE_67PX: {
        name: 'check_success',
        svg: true,
        common: true,
        width: 67,
        centered: true
    },
    CHECK_CIRCLE_OUTLINE_64PX: {
        name: 'check_success',
        svg: true,
        common: true,
        width: 64,
        height: 64,
        centered: true
    },
    CHECK_CIRCLE_OUTLINE: {
        name: 'check_circle_outline',
        svg: true,
        common: true,
        width: 22,
        centered: true
    },
    HIGHLIGHT_OFF: {
        name: 'highlight_off',
        svg: true,
        common: true,
        width: 18,
        centered: true
    },
    CLOSE: {
        name: 'close',
        svg: true,
        common: true,
        width: 17,
        centered: true
    },
    WHAT_HAPPENED: {
        name: 'what_happened',
        svg: false,
        common: false,
        width: 115,
        centered: true
    },
    UPLOAD_DOCS: {
        name: 'upload_docs',
        svg: false,
        common: false,
        width: 115,
        centered: true
    },
    SELECT_PAYOUT_PREFERENCE: {
        name: 'select_payout_preference',
        svg: false,
        common: false,
        width: 115,
        centered: true
    },
    CLAIM_ANALYZED: {
        name: 'claim_analyzed',
        svg: false,
        common: false,
        width: 115,
        centered: true
    },
    ISOLOGO: {
        name: 'isologo',
        common: true,
        width: 130,
        centered: true,
        alt: "favicon"
    },
    APPLE_STORE_BUTTON: {
        name: 'apple_store_button',
        common: true,
        width: 142,
        height: 46,
        centered: true,
        alt: "Download on the App Store"
    },
    GOOGLE_PLAY_BUTTON: {
        name: 'google_play_button',
        common: true,
        width: 150,
        height: 46,
        centered: true,
        alt: "Get it on Google Play"
    },
    ICON_CALENDAR: {
        name: 'icon_calendar',
        width: 100,
    },
    STOPWATCH: {
        name: 'stopwatch',
        width: 80,
    },
    SUCCESS_CONFETTI_90PX: {
        name: 'success_confetti',
        width: 90,
    },
    SUCCESS_CONFETTI_150PX: {
        name: 'success_confetti',
        width: 150,
    },
    ICON_MAP: {
        centered: true,
        name: 'icon_map',
        width: 155,
    },
    CLIPBOARD_WITH_MAGNIFYING_GLASS: {
        name: 'clipboard_with_magnifying_glass',
        width: 119,
    },
    SUCCESS_THANK_YOU: {
        width: 470,
        centered: true,
        name: 'success_thank_you'
    },
    LEVERS: {
        width: 124,
        centered: true,
        name: 'levers'
    },
    CONDITION_MODERATE: {
        name: 'condition_moderate',
        width: 91,
    },
    CONDITION_SEVERE: {
        name: 'condition_severe',
        width: 103,
    },
    CONDITION_CATASTROPHIC: {
        name: 'condition_catastrophic',
        width: 125,
    },
    ICON_CLIPBOARD: {
        name: 'icon_clipboard',
        common: true,
        maxWidth: 100,
        centered: true,
    },
    VENMO_PM_130PX: {
        name: 'venmo',
        common: true,
        width: 130,
        alt:"Venmo payout method"
    },
    PAYPAL_PM_130PX: {
        name: 'paypal',
        common: true,
        width: 130,
        alt:"PayPal payout method"
    },
    VENMO_PM_88PX: {
        name: 'venmo',
        common: true,
        width: 88,
        alt:"Venmo payout method"
    },
    PAYPAL_PM_88PX: {
        name: 'paypal',
        common: true,
        width: 88,
        alt:"PayPal payout method"
    },
});

export default IMAGE;
